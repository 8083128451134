window.newProject = {
    'id': 9,
    'order': 1,
    'name': null,
    'url': null,
    'date': null,
}
window.newSection = {
    'id': 1,
    'order': 1,
    'sectionName': '',
    'projects': []
}

@.myProjectsSiteHandler = (sectionsData) ->
    docElem = document.documentElement
    sectionsGrid = null
    sectionsDataIdMap = {}

    $(document).ready(->
        for sectionData in sectionsData
            sectionsDataIdMap[sectionData.id] = sectionData
        createSectionsGridLayout()
        bindEvents()
    )

    createSectionsGridLayout = ->
        sortSectionsData()
        sectionsGrid = new Muuri('.board', {
            layoutDuration: 400,
            sortData: {
                order: (item, element) ->
                    return parseFloat($(element).data('order'))
            }
            layoutEasing: 'ease',
            dragAxis: 'y',
            dragSortInterval: 0,
            items: generateSections(initialSectionsData),
            dragReleaseDuration: 400,
            dragReleaseEasing: 'ease',
        })

        itemContainers = [].slice.call(document.querySelectorAll('.board__section__content'))
        itemContainers.forEach (container) ->
            projectsGrid = createProjectsGrid(container)
            projectsGrid.sort('order')
            sectionId = parseInt($(container).data('section-id'))
            sectionsDataIdMap[sectionId].projectsGrid = projectsGrid
            return

        sectionsGrid.sort('order')
        sectionsGrid.refreshItems()
        sectionsGrid.layout()

        $('#addNewProject').on('click', (e) ->
            # $('#modalNewProject').parents('.myprojects__modal').addClass('active')
            addProject('#newProjectName', e)
        )
        # ACTION Create new section
        $('#newSection').on('focus', ->
            $('.new-section').addClass('active')
            if $(this).val() == $(this).attr('placeholder')
                if window.innerWidth > window.gridBreakpoints.sm
                    $(this).val('').css({
                        width: ((this.value.length + 1) * 9 + 90 ) + 'px'
                    })
                else
                    $(this).val('')
        ).on('blur', (e) ->
            $('.new-section').removeClass('active')
            if (($(this).val() == '') || ($(this).val() == $(this).attr('placeholder')))
                if window.innerWidth > window.gridBreakpoints.sm
                    $(this).val(gettext('Name Your Section')).css({
                        width: ((this.value.length + 1) * 9 + 90 ) + 'px'
                    })
                else
                    $(this).val(gettext('Name Your Section'))
            else
                addSection(this, e)
                return

        ).on('keyup', (e) ->
            if (e.which == 13)
                $(e.target).blur()
        )
        # Edit section name
        $('.board').on('blur', '.section-name', (e) ->
            sectionId = $(this).parents('.board__section').data('section-id')
            sectionNameBefore = sectionsDataIdMap[sectionId].sectionName
            if ($(this).val() != '') && ($(this).val() != sectionNameBefore)
                sectionsDataIdMap[sectionId].sectionName = $(this).val()
                console.log('rename section')
                ajaxData = {'sectionData': JSON.stringify(customSerialize(sectionsData))}
                updateProjectsAjaxCall(ajaxData, 'updateSections')
        ).on('keyup', (e) ->
            if (e.which == 13)
                $(e.target).blur()
        )

        # ACTION move (swap) sections up and down
        $('.board').on('click', (e) ->
            if elementMatches(e.target, '.board__section__header__down')
                moveSection(e, 'down')
            else if  elementMatches(e.target, '.board__section__header__up')
                moveSection(e, 'up')
            return
        )
        # ACTION remove single section
        $('.board').on('click', (e) ->
            if elementMatches(e.target, '.board__section__header__close')
                $(e.target).parents('.board__section__header').toggleClass('set-to-remove')
            if elementMatches(e.target, '.board__section__header__remove-confirm')
                removeSection(e)
                sectionId = $(e.target).parents('.board__section').data('section-id')
                ajaxData = {'sectionId': sectionId}
                removeProjectsSectionAjaxCall(ajaxData)
                # $('#modalRemoveProject').parents('.myprojects__modal').addClass('active')
                # if kolejne klikniecie na yes to
        )

        # ACTION remove single project
        $('.board').on('click', (e) ->
            if elementMatches(e.target, '.board-item__content__more')
                    $(e.target)
                        .siblings('.board-item__content__remove-confirm')
                        .addClass('active')

            if elementMatches(e.target, '.board-item__content__remove-confirm__content__cancel')
                $(e.target)
                    .parents('.board-item__content__remove-confirm')
                    .removeClass('active')

            if elementMatches(e.target, '.board-item__content__remove-confirm__content__remove')
                removeProject(e)
                projectId = $(e.target).parents('.board-item').data('project-id')
                ajaxData = {'projectId': projectId}
                removeProjectAjaxCall(ajaxData)
        )
        # LOGIC move (swap) sections up and down
        moveSection = (item, direction) ->
            i = 0
            elements = sectionsGrid.getItems()
            while i < elements.length
                if elements[i]._element == elementClosest(item.target, '.board__section')
                    current = elements[i]
                    if (direction == 'down')
                        if i != elements.length - 1
                            next = elements[i + 1]
                        else
                            return
                    else if (direction == 'up')
                        next = elements[i - 1]
                    else
                        return
                    sectionsGrid.move(current, next, { action: 'swap' })
                    updateIndices(sectionsGrid)
                    sectionsGrid.synchronize()
                    ajaxData = {'sectionData': JSON.stringify(customSerialize(sectionsData))}
                    updateProjectsAjaxCall(ajaxData, 'updateSections')
                i++

        # LOGIC Create new section
        addProject = (inputEl, e) ->
            newProjectInput = $(inputEl)
            projectName = newProjectInput.val()

            if projectName
                ajaxData = {'projectName': projectName}
                $.when(createProjectAjaxCall(ajaxData)).done((data, textStatus, jqXHR) ->
                    console.log(data)
                    newProjectData = data.projectData
                    itemElem = document.createElement('div')
                    allProjectsGrid = getAllProjectsGrid()

                    if data.sectionData
                        addSectionElement(data.sectionData)

                    newEl = generateSingleProject(newProjectData)
                    itemElem.innerHTML = newEl
                    firstSectionId =  $(allProjectsGrid[0].getElement()).data('section-id')
                    sortSectionsData()
                    addSingleProjectDataToSection(newProjectData, firstSectionId)
                    allProjectsGrid[0].add(itemElem.firstChild, { index: 0 })
                    allProjectsGrid[0].synchronize()
                    newProjectInput.val('')
                    return
                )
                $('.myprojects__modal').removeClass('active')

        addSectionElement = (newSectionData) ->
            allProjectsGrid = getAllProjectsGrid()
            newSection = generateSingleSection(newSectionData)
            sectionsGrid.add(newSection, { index: 0 })
            newSectionContent = createProjectsGrid(newSection.lastElementChild)
            allProjectsGrid.unshift(newSectionContent)
            newSectionData.projectsGrid = newSectionContent
            sectionsData.push(newSectionData)
            sectionsDataIdMap[newSectionData.id] = newSectionData
            updateIndices(sectionsGrid)
            sortSectionsData()
            return

        # LOGIC Create new section
        addSection = (inputEl, e) ->
            sectionName = $(inputEl).val()
            ajaxData = {'sectionName': sectionName}
            $.when(createProjectsSectionAjaxCall(ajaxData)).done((data, textStatus, jqXHR) ->
                console.log(data)
                addSectionElement(data.sectionData)
                if window.innerWidth > window.gridBreakpoints.sm
                    $(inputEl).val(gettext('Name Your Section')).css({
                        width: ((inputEl.value.length + 1) * 9 + 90 ) + 'px'
                    })
                else
                    $(inputEl).val(gettext('Name Your Section'))
                return
            )


        # Remove Section with projects inside
        removeSection = (e) ->
            elem = elementClosest(e.target, '.board__section')
            sectionId = parseInt($(elem).data('section-id'))
            sectionsGrid.hide(elem, {
                onFinish: (items) ->
                    item = items[0]
                    sectionsGrid.remove(item, { removeElements: true })
                    dataItem = sectionsDataIdMap[sectionId]
                    sectionsData.splice(sectionsData.indexOf(dataItem), 1)
                    delete sectionsDataIdMap[sectionId]
                }
            )
            return

        # Remove single project
        removeProject = (e) ->
            elem = elementClosest(e.target, '.board-item')
            projectId = parseInt($(elem).data('project-id'))
            sectionId = parseInt($(elem).parents('.board__section').data('section-id'))
            projectToRemoveData = sectionsDataIdMap[sectionId].projects.find((project) -> project.id == projectId )
            projectsGrid = sectionsDataIdMap[sectionId].projectsGrid
            removeSingleProjectDataFromSection(projectToRemoveData, sectionId)
            projectsGrid.hide(elem, {
                onFinish: (items) ->
                    item = items[0]
                    projectsGrid.remove(item, { removeElements: true })
                    updateIndices(projectsGrid, 'project-id', sectionId)
                }
            )
            return

    # Initial generate section group
    generateSections = (sections) ->
        ret = []

        for sectionData in sections
            ret.push(generateSingleSection(sectionData))
        return ret

    # Generate section HTML content
    generateSingleSection = (singleSection) ->
        sectionElem = document.createElement('div')
        sectionId = singleSection.id
        projects = generateProjects(singleSection.projects)
        placeholder = gettext('Name Your Section')
        remove_txt = gettext('Yes, Remove Section')
        sectionTemplate = '' +
            '<div class="board__section" data-section-id="' + sectionId + '" data-order="' +
                    singleSection.order + '">' +
                '<div class="board__section__header">' +
                    '<div class="board__section__header__title">' +
                            '<span class="fixed-width-input">' +
                                '<input class="section-name" value="' + singleSection.sectionName + '" placeholder="' + placeholder + '" type="text">' +
                            '</span>' +
                    '</div>' +
                    '<div class="board__section__header__divider"></div>' +
                    '<div class="board__section__header__remove-confirm">' + remove_txt + '</div>' +
                    '<div class="board__section__header__close"></div>' +
                    '<div class="board__section__header__down"></div>' +
                    '<div class="board__section__header__up"></div>' +
                    '<div class="board__section__header__options"></div>' +
                '</div>' +
                '<div class="board__section__content" data-section-id="' + sectionId + '">' + projects + '</div>' +
            '</div>';
        sectionElem.innerHTML = sectionTemplate
        return sectionElem.firstChild

    # Initial generate project group inside section
    generateProjects = (projects) ->
        ret = ''
        for project in projects
            ret += generateSingleProject(project)
        return ret

    # Generate project HTML content
    generateSingleProject = (project) ->
        itemTemplate =
            '<div class="board-item"  id="project-' + project.id + '"
                data-project-id="' + project.id + '" data-order="' + project.order + '">' +
                '<div class="board-item__content">' +
                    '<div class="board-item__content__remove-confirm">' +
                        '<div class="board-item__content__remove-confirm__content">' +
                            '<div class="board-item__content__remove-confirm__content__text">' + gettext('Delete this project ?') + '</div>' +
                            '<div class="board-item__content__remove-confirm__content__remove">' + gettext('Yes, Delete') + '</div>' +
                            '<div class="board-item__content__remove-confirm__content__cancel">' + gettext('Cancel') + '</div>' +
                        '</div>' +
                    '</div>' +
                    '<div class="board-item__content__title">' + project.name + '</div>' +
                    '<div class="board-item__content__date">' + gettext('Date Created:') + '</div>' +
                    '<div class="board-item__content__date">' + project.date + '</div>' +
                    '<a href="' + project.url + '" class="board-item__content__button">' + gettext('open project') + '</a>' +
                    '<div class="board-item__content__more">' +
                        '<div class="board-item__content__more__icon"></div>' +
                     '</div>' +
                '</div>' +
            '</div>'
        return itemTemplate

    bindEvents = ->
        $('.fixed-width-input input').each( ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).css({
                    width: ((this.value.length + 1) * 9 + 90 ) + 'px'
                })
        )
        $('.myprojects__container').on('keyup', '.fixed-width-input input', ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).css({
                    width: ((this.value.length + 1) * 9 + 90 ) + 'px'
                })
        )

        # Open "ADD NEW PROJECT" modal
        $('.myprojects__container').on('click', (e) ->
            if elementMatches(e.target, '.myprojects__container__add-project')
                $('#modalNewProject').parents('.myprojects__modal').addClass('active')
        )
        $('.board').on('mouseenter', '.board-item__content__more', (event) ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).addClass('active').siblings('.board-item__content__remove-dropdown').addClass('active')
        )
        $('.board').on('mouseleave', '.board-item__content__remove-dropdown__content', (event) ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).parent().removeClass('active').siblings('.board-item__content__more').removeClass('active')
        )
        $('.board').on('click', '.board-item__content__more', (event) ->
            if window.innerWidth < window.gridBreakpoints.sm
                $(this).toggleClass('active').siblings('.board-item__content__remove-dropdown').toggleClass('active')
        )

    # funkcje pomocniczne
    createProjectsGrid = (container) ->
        dragCounter = 0
        return new Muuri(container, {
            items: '.board-item'
            layoutDuration: 400
            layoutEasing: 'ease'
            dragEnabled: true
            dragSort: ->
                return getAllProjectsGrid()

            dragSortInterval: 0
            dragContainer: document.body
            dragReleaseDuration: 400
            dragReleaseEasing: 'ease'
            dragStartPredicate: (item, event) ->
                preventDragging = elementMatches(event.target,
                    '.board-item__content__button,
                     .board-item__content__more__icon,
                     .board-item__content__remove-dropdown__content__text,
                     .board-item__content__remove-dropdown__content__icon,
                     .board-item__content__more,
                     .board-item__content__remove-confirm,
                     .board-item__content__remove-confirm__content__remove,
                     .board-item__content__remove-confirm__content__cancel,
                     .board-item__content__remove-confirm__content
                ')
                if !preventDragging then return Muuri.ItemDrag.defaultStartPredicate(item, event) else return false
            sortData: {
                order: (item, element) ->
                    return parseFloat($(element).data('order'))
            }
        }).on('dragStart', (item) ->
            ++dragCounter
            docElem.classList.add('dragging')
            item.getElement().style.width = item.getWidth() + 'px'
            item.getElement().style.height = item.getHeight() + 'px'
            return
        ).on('dragEnd', (item) ->
            if --dragCounter < 1
                docElem.classList.remove('dragging')
            return
        ).on('beforeReceive', (data) ->
            beforeSectionId = $(data.fromGrid.getElement()).data('section-id')
            afterSectionId = $(data.toGrid.getElement()).data('section-id')
            projectId = $(data.item.getElement()).data('project-id')
            moveProjectDataFromSectionToSection(projectId, beforeSectionId, afterSectionId)
            return
        ).on('dragReleaseEnd', (item) ->
            item.getElement().style.width = ''
            item.getElement().style.height = ''
            allProjectsGrid = getAllProjectsGrid()
            allProjectsGrid.forEach((projectsGrid, index) ->
                sectionId = $(projectsGrid.getElement()).data('section-id')
                updateIndices(projectsGrid, 'project-id', sectionId)
                projectsGrid.refreshItems()
                return
            )
            ajaxData = {'sectionData': JSON.stringify(customSerialize(sectionsData))}
            updateProjectsAjaxCall(ajaxData, 'updateSections')
            return
        ).on('layoutStart', ->
            sectionsGrid.refreshItems().layout()
            return
        )

    getAllProjectsGrid = ->
        ret = []
        for sectionData in sectionsData
            ret.push(sectionData.projectsGrid) if sectionData.projectsGrid
        return ret

    moveProjectDataFromSectionToSection = (projectId, beforeSectionId, afterSectionId, mapId = sectionsDataIdMap) ->
        movedProject = mapId[beforeSectionId].projects.find((project) -> project.id == parseInt(projectId) )
        removeSingleProjectDataFromSection(movedProject, beforeSectionId)
        addSingleProjectDataToSection(movedProject, afterSectionId)

    removeSingleProjectDataFromSection = (singleProject, beforeSectionId, mapId = sectionsDataIdMap) ->
        sectionProjects = mapId[beforeSectionId].projects
        sectionProjects.splice(sectionProjects.indexOf(singleProject), 1)

    addSingleProjectDataToSection = (singleProject, afterSectionId, mapId = sectionsDataIdMap) ->
        mapId[afterSectionId].projects.push(singleProject)

    sortSectionsData = (sections = sectionsData) ->
        sections.sort((a, b) -> return a.order > b.order)

    updateIndices = (grid, idAttr = 'section-id', sectionId, mapId = sectionsDataIdMap) ->
        gridIndex = sectionId
        grid.getItems().forEach((item, i) ->
            element = $(item.getElement())
            newOrder = i + 1
            itemId = parseInt(element.data(idAttr))
            if idAttr == 'section-id'
                mapId[itemId].order = newOrder
                element.data('order', newOrder)
            else if idAttr == 'project-id'
                elementId = element.data('project-id')
                mapId[gridIndex].projects.find((project) -> project.id == elementId ).order = newOrder
            element.data('order', newOrder)
        )
        sortSectionsData()

    Date::ddmmyyyy = ->
        dd = @getDate().toString()
        mm = (@getMonth() + 1).toString()
        yyyy = @getFullYear().toString()
        return (if dd[1] then dd else '0' + dd[0]) + '.' + (if mm[1] then mm else '0' + mm[0]) + '.' + yyyy

    elementMatches = (element, selector) ->
        p = Element.prototype
        (p.matches or p.matchesSelector or p.webkitMatchesSelector or p.mozMatchesSelector or
            p.msMatchesSelector or p.oMatchesSelector).call element, selector

    elementClosest = (element, selector) ->
        if window.Element and !Element::closest
            isMatch = elementMatches(element, selector)
            while !isMatch and element and element != document
                element = element.parentNode
                isMatch = element and element != document and elementMatches(element, selector)
            if element and element != document then element else null
        else
            element.closest selector

    customSerialize = (object) ->
        newArr = []
        for item in object
            newObj = {}
            newObj.id = item.id
            newObj.order = item.order
            newObj.projects = item.projects
            newObj.sectionName = item.sectionName
            newArr.push(newObj)
        console.log(newArr)
        return newArr

    updateProjectsAjaxCall = (data, type) ->
        $.ajax({
            type: 'POST'
            url: '/myspacestor/update-myprojects/'
            data: data
            success: (data) ->
                return data
            error: (data) ->
                console.log(data)
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })

    createProjectAjaxCall = (data) ->
        $.ajax({
            type: 'POST'
            url: '/myspacestor/add-project/'
            data: data
            success: (data) ->
                return data
            error: (data) ->
                console.log(data)
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })

    createProjectsSectionAjaxCall = (data) ->
        $.ajax({
            type: 'POST'
            url: '/myspacestor/add-section/'
            data: data
            success: (data) ->
                return data
            error: (data) ->
                console.log(data)
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })

    removeProjectAjaxCall = (data) ->
        $.ajax({
            type: 'POST'
            url: '/myspacestor/remove-project/'
            data: data
            success: (data) ->
                return data
            error: (data) ->
                console.log(data)
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })

    removeProjectsSectionAjaxCall = (data) ->
        $.ajax({
            type: 'POST'
            url: '/myspacestor/remove-section/'
            data: data
            success: (data) ->
                return data
            error: (data) ->
                console.log(data)
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
